import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAssetBus, LazySvgoBusIllustration, LazySvgoBusPlaceholder, LazySvgoFleequidLogo, LazySvgoHpHeroBusPrimary50, LazySvgoHpHeroBusPrimary500, LazySvgoHpHeroBusSecondary500, LazySvgoHpWhatIsProcess, LazySvgoHpWhatIsSpeed, LazySvgoHpWhatIsTransparency, LazySvgoHpWhyEasyAuctions, LazySvgoHpWhyEuReach, LazySvgoHpWhyOperationalEfficiency, LazySvgoHpWhySell, LazySvgoHpWhyUnifiedBilling, LazySvgoMktgDeal, LazySvgoMktgProcesses, LazySvgoMktgSupport, LazySvgoSteeringWheel, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["SvgoAssetBus", LazySvgoAssetBus],
["SvgoBusIllustration", LazySvgoBusIllustration],
["SvgoBusPlaceholder", LazySvgoBusPlaceholder],
["SvgoFleequidLogo", LazySvgoFleequidLogo],
["SvgoHpHeroBusPrimary50", LazySvgoHpHeroBusPrimary50],
["SvgoHpHeroBusPrimary500", LazySvgoHpHeroBusPrimary500],
["SvgoHpHeroBusSecondary500", LazySvgoHpHeroBusSecondary500],
["SvgoHpWhatIsProcess", LazySvgoHpWhatIsProcess],
["SvgoHpWhatIsSpeed", LazySvgoHpWhatIsSpeed],
["SvgoHpWhatIsTransparency", LazySvgoHpWhatIsTransparency],
["SvgoHpWhyEasyAuctions", LazySvgoHpWhyEasyAuctions],
["SvgoHpWhyEuReach", LazySvgoHpWhyEuReach],
["SvgoHpWhyOperationalEfficiency", LazySvgoHpWhyOperationalEfficiency],
["SvgoHpWhySell", LazySvgoHpWhySell],
["SvgoHpWhyUnifiedBilling", LazySvgoHpWhyUnifiedBilling],
["SvgoMktgDeal", LazySvgoMktgDeal],
["SvgoMktgProcesses", LazySvgoMktgProcesses],
["SvgoMktgSupport", LazySvgoMktgSupport],
["SvgoSteeringWheel", LazySvgoSteeringWheel],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
