// reference: https://gearboxgo.com/articles/web-application-development/setting-up-sentry-with-nuxt-3

import * as Sentry from '@sentry/vue';
import lodashPick from 'lodash-es/pick';

function getSentryIntegrations(): any[] {
    // don't load on server
    if (!process.client) return [];

    const router = useRouter();

    return [Sentry.browserTracingIntegration({ router }), Sentry.browserProfilingIntegration(), Sentry.httpClientIntegration()];
}

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        const vueApp = nuxtApp.vueApp;

        const config = useRuntimeConfig();

        if (import.meta.server || config.public.sentryDisabled) {
            return;
        }

        // COMPOSABLES
        const { data } = useAuthUtils();

        Sentry.init({
            app: vueApp,
            dsn: config.public.sentryDsnPublic ?? null,
            integrations: getSentryIntegrations(),
            environment: config.public.sentryEnvironment,
            release: config.public.sentryRelease,
            debug: config.public.sentryDebug,

            // https://docs.sentry.io/platforms/javascript/guides/vue/enriching-events/context/
            // To ensure that event data is sufficiently complete
            normalizeDepth: 5,

            // Integration Browser Tracing
            // Track all vue components
            trackComponents: true,

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: config.public.sentryTracesSampleRate,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [config.public.frontendBaseUrl, config.public.backendApiBaseUrl],

            // Integration HttpClient
            // This option is required for capturing headers and cookies.
            sendDefaultPii: true,
        });

        watchEffect(() => {
            Sentry.setUser(data.value ? lodashPick(data.value, ['id']) : null);
        });
    },
});
