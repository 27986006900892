import type { FormError } from '#ui/types';

import { BackendErrorCode, type BackendErrorMessageEntity } from '~/apiClient';
import type { PathsOf } from '~/types/pathsOf.type';

type TypedBackendErrorMessage<BackendDto extends object> = Omit<BackendErrorMessageEntity, 'fullPathProperty'> & {
    fullPathProperty: PathsOf<BackendDto>;
};

type GetFormErrors = <BackendDto extends object, FormType extends object>(
    error: BackendErrorMessageEntity[] | undefined,
    propertiesMap: { [key in PathsOf<BackendDto>]?: PathsOf<FormType> }
) => FormError<string>[];

type UseBackendErrorsReturn = {
    getFormErrors: GetFormErrors;
    getErrorDescription: GetErrorDescription;
};

export type ErrorCodesToMessages = Partial<Record<BackendErrorCode, TranslationKey>>;

type GetErrorDescription = (error: BackendErrorMessageEntity, customErrorCodeToMessages?: ErrorCodesToMessages) => TypedTranslateReturn;

const genericErrorCodesToMessages: ErrorCodesToMessages = {
    NotValidEmail: 'validation.msg.email',
    WeakPassword: 'validation.msg.passwordWeak',
    InvalidPhoneNumber: `validation.msg.phone`,
    AuthTokenNotSubmitted: `error.backend.authentication`,
    AuthTokenNotValid: `error.backend.authentication`,
    AuthNotEnoughPermission: `error.backend.authentication`,
    AuthTokenExpired: `error.backend.authentication`,
    AuthTokenMalformed: `error.backend.authentication`,
    InvalidCaptcha: `validation.msg.recaptcha`,
    MissingCaptcha: `validation.msg.recaptcha`,
};

export function useBackendErrors(): UseBackendErrorsReturn {
    const { tt, tte } = useTypedI18n();

    /**
     *  Returns the FIRST error in a form submitted to an API
     *  Provides:
     *  - type inference for the paths of request body properties
     *  - internationalization for messages associated with error codes
     */
    const getFormErrors: GetFormErrors = <BackendDto extends object, FormType extends object>(
        errors: BackendErrorMessageEntity[] | undefined,
        propertiesMap: { [key in PathsOf<BackendDto>]?: PathsOf<FormType> }
    ): FormError<string>[] => {
        if (!errors?.length) return [];

        const hasFullPath = (error: BackendErrorMessageEntity): error is TypedBackendErrorMessage<BackendDto> => {
            return !!error.fullPathProperty;
        };

        const typedError = errors.find(error => !!(hasFullPath(error) && propertiesMap[error.fullPathProperty])) as
            | TypedBackendErrorMessage<BackendDto>
            | undefined;

        return typedError
            ? [
                  {
                      path: propertiesMap[typedError.fullPathProperty] as string,
                      message: getErrorDescription(typedError),
                  },
              ]
            : [];
    };

    /**
     * An error that occurs when a property is not defined indicates an issue outside of the request body.
     * This type of error can likely be displayed at a more general level.
     */
    const getErrorDescription: GetErrorDescription = (error, customErrorCodeToMessages) => {
        const errorTranslationKey = customErrorCodeToMessages?.[error.code] ?? genericErrorCodesToMessages[error.code];
        if (errorTranslationKey) return tt(errorTranslationKey);

        if (tte(`error.backend.${error.code}`)) return tt(`error.backend.${error.code}` as TranslationKey);

        console.warn(`Error ${error.code} does not have a specific message`, error.message);

        return tt('notifications.genericError');
    };

    return { getFormErrors, getErrorDescription };
}
