<script setup lang="ts">
import type { NuxtError } from '#app';

type ManagedStatusCode = 500 | 404;

// PROPS
const props = defineProps({
    error: { type: Object as () => NuxtError, default: null },
});

// COMPOSABLES
const { tt } = useTypedI18n();
const { message, stacktrace } = useDevelopmentError();

// DATA
const statusCode = computed<ManagedStatusCode | 'default'>(
    () => (<ManagedStatusCode[]>[500, 404]).find(item => item === props.error?.statusCode) || 'default'
);

// HANDLERS
const handleClick = (): Promise<void> => clearError({ redirect: '/' });
</script>

<template>
    <NuxtLayout name="default">
        <BaseWidthContainer class="flex flex-1 flex-col justify-center">
            <section class="py-8">
                <div class="mx-auto max-w-screen-lg text-center">
                    <h1 class="text-primary mb-4 text-7xl font-bold tracking-tight lg:text-9xl">
                        {{ tt(`error.${statusCode}.title`) }}
                    </h1>
                    <p class="mb-4 text-3xl font-bold tracking-tight text-gray-900 md:text-4xl">
                        {{ tt(`error.${statusCode}.subtitle`) }}
                    </p>
                    <p class="mb-4 text-lg font-light text-gray-500">
                        {{ tt(`error.${statusCode}.description`) }}
                    </p>
                    <i18n-t v-if="statusCode === 500" keypath="error.500.help.base" tag="div" class="mb-4 text-sm font-light">
                        <template #contactUs>
                            <i18n-t v-if="statusCode === 500" keypath="error.500.help.contactUs" tag="span">
                                <template #email>
                                    <ULink active-class="text-primary" inactive-class="text-gray-500" to="mailto:info@fleequid.com">
                                        info@fleequid.com
                                    </ULink>
                                </template>
                                <template #phone>
                                    <ULink active-class="text-primary" inactive-class="text-gray-500" to="tel:+39 0287176063">+39 0287176063</ULink>
                                </template>
                            </i18n-t>
                        </template>
                    </i18n-t>
                    <UButton color="primary" variant="solid" size="lg" @click="handleClick">
                        {{ tt(`error.${statusCode}.button`) }}
                    </UButton>
                </div>

                <div v-if="statusCode === 500 && (!!message || stacktrace.length > 0)" class="mt-16">
                    <p v-if="message" class="text-left text-xl font-medium">{{ message }}</p>
                    <div v-if="stacktrace.length > 0" class="mb-5 h-auto flex-1 overflow-y-auto rounded-t-md bg-black/5 bg-white">
                        <pre class="z-10 block text-left font-mono text-sm font-light">
                            <span v-for="(trace, index) in stacktrace" :key="index" class="block whitespace-break-spaces" :class="{'font-semibold': !trace.internal}">{{trace.text}}</span>
                        </pre>
                    </div>
                </div>
            </section>
        </BaseWidthContainer>
    </NuxtLayout>
</template>
