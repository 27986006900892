import type { ButtonColor } from '#ui/types';

export type PaletteBgColors = Extract<ButtonColor, 'black' | 'white' | 'gray' | 'primary' | 'fl-primary' | 'fl-dark-blue' | 'fl-secondary'>;
type ValuesOf<T> = T[keyof T];

const COLORS: Record<PaletteBgColors, string> = {
    black: 'bg-black',
    white: 'bg-white',
    gray: 'bg-gray-500',
    primary: 'bg-primary-500',
    'fl-primary': 'bg-fl-primary-500',
    'fl-secondary': 'bg-fl-secondary-500',
    'fl-dark-blue': 'bg-fl-dark-blue',
};

export default (color: PaletteBgColors): ValuesOf<typeof COLORS> => COLORS[color];
