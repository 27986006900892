<script setup lang="ts">
defineProps({
    autocomplete: { type: String as PropType<'new-password' | 'current-password'>, required: true },
});
const model = defineModel<string>();
const isPasswordVisible = ref<boolean>(false);
</script>

<template>
    <UInput
        v-model.trim="model"
        :type="isPasswordVisible ? 'text' : 'password'"
        :autocomplete="autocomplete"
        :ui="{ icon: { trailing: { pointer: '' } } }"
    >
        <template #trailing>
            <UButton
                color="gray"
                variant="link"
                :icon="isPasswordVisible ? 'i-heroicons-eye-slash-20-solid' : 'i-heroicons-eye-20-solid'"
                :padded="false"
                @click="isPasswordVisible = !isPasswordVisible"
            />
        </template>
    </UInput>
</template>
