import { BackendErrorCode } from '~/apiClient';

type UseSessionExpirationReturn = {
    isSessionExpired: (errorCode?: BackendErrorCode) => boolean;
    sessionExpiredHandler: () => void;
};

export const useSessionExpiration = (): UseSessionExpirationReturn => {
    // COMPOSABLES
    const { openSessionExpiredModal } = useModals();

    return {
        isSessionExpired: (errorCode?: BackendErrorCode): boolean => {
            return errorCode === BackendErrorCode.AuthAuthenticationVersionMismatch;
        },

        sessionExpiredHandler: (): void => {
            openSessionExpiredModal();
        },
    };
};
