import revive_payload_client_YTGlmQWWT5 from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/opt/render/project/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_kpnqASteq8 from "/opt/render/project/src/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_j3xPeZV1re from "/opt/render/project/src/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import slideovers_oeUu8xcI5w from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_qI5jN0JlD2 from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_LeAKZ8VmiU from "/opt/render/project/src/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_VfWFs4sWWP from "/opt/render/project/src/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import i18n_dOTnCc6TUQ from "/opt/render/project/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import error_handler_kEP5FliEXj from "/opt/render/project/src/apps/frontend/plugins/error-handler.ts";
import sentry_3AyO8nEfhE from "/opt/render/project/src/apps/frontend/plugins/sentry.ts";
import sessionExpiration_0kmdyyoC1R from "/opt/render/project/src/apps/frontend/plugins/sessionExpiration.ts";
import swiper_eteSCvuYkd from "/opt/render/project/src/apps/frontend/plugins/swiper.ts";
import timeSync_client_wAmKfexdIs from "/opt/render/project/src/apps/frontend/plugins/timeSync.client.ts";
import vueQuery_HbXfDZmQp2 from "/opt/render/project/src/apps/frontend/plugins/vueQuery.ts";
import wsAuctions_client_uUFKFNaDbZ from "/opt/render/project/src/apps/frontend/plugins/wsAuctions.client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_kpnqASteq8,
  plugin_j3xPeZV1re,
  slideovers_oeUu8xcI5w,
  modals_qI5jN0JlD2,
  colors_LeAKZ8VmiU,
  plugin_client_VfWFs4sWWP,
  i18n_dOTnCc6TUQ,
  chunk_reload_client_SeG0EjL5Ec,
  error_handler_kEP5FliEXj,
  sentry_3AyO8nEfhE,
  sessionExpiration_0kmdyyoC1R,
  swiper_eteSCvuYkd,
  timeSync_client_wAmKfexdIs,
  vueQuery_HbXfDZmQp2,
  wsAuctions_client_uUFKFNaDbZ
]