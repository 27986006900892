// Documentation: https://adorea.atlassian.net/wiki/spaces/BUSTARD/pages/46727186/Time+Sync

import { apiGetServerTime } from '~/apiClient';
import { TimeSyncStatus } from '~/types/timeSync.type';

const MAX_DRIFT = 500;
const MAX_RETRIES = 3;
const RETRIES_DELAY = 30 * 1000;

export default defineNuxtPlugin(() => {
    const {
        _raw: { now, offset, roundTrip, status },
    } = useTimeSync();
    const { logError } = useLogs();

    let retriesCount = 0;

    const getDeltaTick = (timestamp: number): number => 1000 - (timestamp % 1000);

    const calculateOffset = async (): Promise<void> => {
        try {
            const requestTime = Date.now();
            const data = await apiGetServerTime({ headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' } });
            const responseTime = Date.now();
            const serverTime = data.time;
            offset.value = (serverTime - requestTime + (serverTime - responseTime)) / 2;
            roundTrip.value = responseTime - requestTime;
            status.value = TimeSyncStatus.Sync;
            retriesCount = 0;
        } catch (error) {
            logError('Error in time sync', { error });
            status.value = TimeSyncStatus.Local;
            retriesCount++;
            retriesCount < MAX_RETRIES && setTimeout(calculateOffset, RETRIES_DELAY);
        }
    };

    const updateTime = async (): Promise<void> => {
        const expectedTime = now.value ? now.value + getDeltaTick(now.value) : null;

        if (
            status.value !== TimeSyncStatus.Local &&
            document.visibilityState === 'visible' &&
            (!expectedTime || !offset.value || Math.abs(expectedTime - Date.now() - offset.value) > MAX_DRIFT)
        ) {
            await calculateOffset();
        }

        now.value = Date.now() + (offset.value ?? 0);
        setTimeout(updateTime, getDeltaTick(now.value));
    };

    updateTime();
});
