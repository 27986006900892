type SafeJsonParse = <T>(jsonString: string, errorLogContext?: string) => T | undefined;

export const useSafeJsonParse = (): SafeJsonParse => {
    const { logError } = useLogs();

    return <T>(jsonString: string, errorLogContext?: string): T | undefined => {
        try {
            const jsonValue: T = JSON.parse(jsonString);

            return jsonValue;
        } catch (error) {
            logError('Error parsing JSON', { error, jsonString, ...(errorLogContext && { context: errorLogContext }) });

            return undefined;
        }
    };
};
