import type { RouteLocationRaw } from 'vue-router';

export enum AuthGuardMode {
    AUTHENTICATED_ONLY = 'authenticated_only',
    GUEST_ONLY = 'guest_only',
}

export type AuthGuardMeta = {
    mode: AuthGuardMode;
    redirect?: RouteLocationRaw;
};

export type AuthGuardAppConfig = {
    redirect: Record<AuthGuardMode, RouteLocationRaw>;
};

declare module '#app' {
    interface PageMeta {
        'auth-guard'?: AuthGuardMeta;
    }
}

declare module 'vue-router' {
    interface RouteMeta {
        authGuard?: AuthGuardMeta;
    }
}

declare module 'nuxt/schema' {
    interface AppConfig {
        authGuard: AuthGuardAppConfig;
    }
}
