<script setup lang="ts">
import { z } from 'zod';

import type { UInput } from '#components';
import { type BackendErrorEntity, type ErrorType, useApiRequestPasswordReset } from '~/apiClient';
import { NotificationTimeout } from '~/types/notifications.type';

// COMPOSABLES
const { tt } = useTypedI18n();
const { notifySuccess } = useNotification();
const { closeRequestPasswordResetModal } = useModals();
const { getErrorDescription } = useBackendErrors();
const { isMobile } = useResponsive();
const { mutateAsync: mutateSubmit, isPending: isSubmitting } = useApiRequestPasswordReset();

// DATA
const formSchema = z.object({
    email: zodRequiredString(tt('validation.msg.required', { field: tt('login.email') })).email(
        tt('validation.msg.invalid', { field: tt('login.email') })
    ),
});

type FormData = z.infer<typeof formSchema>;

const formData: FormData = reactive({
    email: '',
});

const firstInputRef = ref<typeof UInput>();

const submitErrorMessage = ref<string | null>(null);

// METHODS
const onSubmit = async (): Promise<void> => {
    submitErrorMessage.value = null;
    mutateSubmit({ data: { email: formData.email } })
        .then(() => {
            closeRequestPasswordResetModal();
            notifySuccess({
                title: tt('passwordReset.request.onSuccessTitle'),
                description: tt('passwordReset.request.onSuccessDescription'),
                timeout: NotificationTimeout.Long,
            });
        })
        .catch((apiError: ErrorType<BackendErrorEntity>) => {
            if (isBackendError(apiError.data)) {
                submitErrorMessage.value = getErrorDescription(apiError.data.error);
            } else {
                submitErrorMessage.value = tt('notifications.genericError');
            }
        });
};

// LIFECYCLE
onMounted(() => {
    nextTick(() => {
        firstInputRef.value && firstInputRef.value.$refs.input.focus();
    });
});
</script>

<template>
    <UModal :ui="{ width: 'md:max-w-md' }" :fullscreen="isMobile" :transition="!isMobile" @close="closeRequestPasswordResetModal">
        <UButton class="absolute right-6 top-6 p-1" variant="ghost" @click="closeRequestPasswordResetModal">
            <UIcon name="i-heroicons-x-mark-20-solid" class="text-2xl text-black" />
        </UButton>
        <div class="p-6 md:p-16">
            <UForm class="flex flex-col items-center" :state="formData" :schema="formSchema" @submit="onSubmit">
                <p class="text-center text-3xl font-semibold">{{ tt('passwordReset.request.title') }}</p>
                <p class="mt-4 text-center text-gray-600">{{ tt('passwordReset.request.subTitle') }}</p>

                <UFormGroup class="mt-8 w-full" :label="tt('common.email')" name="email" required>
                    <UInput ref="firstInputRef" v-model.trim="formData.email" autocomplete="email" :disabled="isSubmitting" />
                </UFormGroup>

                <BaseAlert v-if="submitErrorMessage" class="mt-8" :title="submitErrorMessage" type="error" />

                <BaseIconBgButton class="mt-8" :label="tt('passwordReset.request.submitButton')" type="submit" size="xl" :loading="isSubmitting" />
            </UForm>
        </div>
    </UModal>
</template>
