import * as Sentry from '@sentry/vue';
import { type SeverityLevel } from '@sentry/vue';
import lodashMapValues from 'lodash-es/mapValues';

type LogFunction = (message: string, data?: Record<string, unknown>) => void;
type UseLogsReturn = {
    logInfo: LogFunction;
    logWarn: LogFunction;
    logError: LogFunction;
};

export const useLogs = (): UseLogsReturn => {
    const makeTrackLog = (level: SeverityLevel): LogFunction => {
        return (message: string, data?: Record<string, unknown>): void => {
            if (import.meta.dev) console.log(level.toUpperCase(), message, data);

            const dataPOJO = lodashMapValues(data, value => {
                try {
                    return JSON.parse(JSON.stringify(value));
                } catch (error) {
                    return { error, message: `error while parsing` };
                }
            });

            Sentry.captureEvent({
                level,
                message,
                timestamp: Date.now(),
                contexts: { data: dataPOJO },
            });
        };
    };

    return {
        logInfo: makeTrackLog('info'),
        logWarn: makeTrackLog('warning'),
        logError: makeTrackLog('error'),
    };
};
