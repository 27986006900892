<script setup lang="ts">
// COMPOSABLES
const { tt } = useTypedI18n();
const { closeSessionExpiredModal, openLoginModal } = useModals();
const { isMobile } = useResponsive();
</script>

<template>
    <UModal :ui="{ width: 'md:max-w-md' }" :fullscreen="isMobile" :transition="!isMobile" prevent-close>
        <div class="p-6 md:p-16">
            <p class="text-center text-3xl font-semibold">{{ tt('sessionExpired.title') }}</p>
            <p class="my-4 text-center text-gray-600">{{ tt('sessionExpired.description') }}</p>
            <div class="flex w-full flex-row justify-end gap-3">
                <UButton color="primary" variant="ghost" :label="tt('sessionExpired.close')" @click="closeSessionExpiredModal" />
                <UButton color="primary" variant="solid" :label="tt('common.login')" @click="openLoginModal" />
            </div>
        </div>
    </UModal>
</template>
