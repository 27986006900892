import { AuthGuardMode } from '~/types/authGuard';

export default defineAppConfig({
    authGuard: {
        // Configurations for authGuard middleware
        redirect: {
            [AuthGuardMode.AUTHENTICATED_ONLY]: 'index',
            [AuthGuardMode.GUEST_ONLY]: 'index',
        },
    },
    ui: {
        primary: 'fl-primary',
        gray: 'neutral', // This settings override the gray palette, now all gray shades are neutral
        notifications: {
            position: 'top-0 bottom-auto',
        },
        accordion: {
            default: {
                openIcon: 'i-heroicons-plus',
                closeIcon: 'i-heroicons-minus',
            },
        },
        badge: {
            rounded: 'rounded-full',
            color: {
                'fl-dark-blue': {
                    solid: 'text-white bg-fl-dark-blue ',
                },
            },
            variant: {
                soft: 'bg-{color}-50 dark:bg-{color}-400 dark:bg-opacity-10 text-{color}-950 dark:text-{color}-400',
            },
        },
        button: {
            color: {
                'fl-dark-blue': {
                    solid: 'shadow-sm text-white bg-fl-dark-blue hover:bg-fl-dark-blue-800 disabled:bg-gray-900 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500',
                    link: 'text-fl-dark-blue underline-offset-4 hover:underline focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500',
                },
            },
            default: {
                loadingIcon: 'i-svg-spinners-270-ring-with-bg',
            },
            icon: {
                loading: 'animate-none',
            },
            rounded: 'rounded-full',
            variant: {
                soft: 'text-{color}-950 dark:text-{color}-400 bg-{color}-50 hover:bg-{color}-100 disabled:bg-{color}-50 dark:bg-{color}-950 dark:hover:bg-{color}-900 dark:disabled:bg-{color}-950 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-{color}-500 dark:focus-visible:ring-{color}-400',
            },
        },
        formGroup: {
            default: {
                size: 'xl',
            },
            label: {
                base: 'leading-5 tracking-normal',
            },
            size: {
                xl: 'text-sm',
            },
            error: 'form-group-error text-sm',
            hint: 'text-sm',
            help: 'text-sm',
        },
        input: {
            default: {
                size: 'xl',
            },
            file: {
                padding: {
                    xl: 'h-11', // Fix height issue on Safari (https://adorea.atlassian.net/browse/BSD-311)
                },
            },
        },
        select: {
            default: {
                size: 'xl',
            },
        },
        checkbox: {
            base: 'cursor-pointer',
        },
        pagination: {
            default: {
                activeButton: {
                    class: 'font-bold',
                },
                inactiveButton: {
                    class: 'font-bold text-black',
                },
                prevButton: {
                    icon: 'i-mdi-arrow-back',
                },
                nextButton: {
                    icon: 'i-mdi-arrow-forward',
                },
            },
        },
        modal: {
            overlay: {
                background: 'bg-gray-200/35',
            },
            fullscreen: 'h-dvh', // Set dvh instead of entire screen to prevent footer of modals to be outside viewport
        },
    },
    // Fix build error "Property 'nuxtIcon' is missing in type 'AppConfig'" (https://github.com/nuxt/ui/issues/1045)
    nuxtIcon: {},
    webSocket: {
        autoReconnect: {
            retries: 12 * 60 * 24 * 7, // 1 week
            delay: 5000,
        },
        heartbeat: {
            interval: 10 * 60 * 1000,
            pongTimeout: 3000,
        },
    },
});
