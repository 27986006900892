export enum Locale {
    DE = 'de',
    ES = 'es',
    EN = 'en',
    IT = 'it',
    PL = 'pl',
}

const LOCALE_ISO_MAP = {
    [Locale.DE]: 'de-DE',
    [Locale.ES]: 'es-ES',
    [Locale.EN]: 'en-GB',
    [Locale.IT]: 'it-IT',
    [Locale.PL]: 'pl-PL',
};

export const locales = Object.values(Locale).map(code => ({ code, iso: LOCALE_ISO_MAP[code] }));

export const defaultLocale = Locale.EN;
