import { Query, type QueryCacheNotifyEvent, useQueryClient } from '@tanstack/vue-query';
import { useWebSocket } from '@vueuse/core';
import lodashKeyBy from 'lodash-es/keyBy';

import {
    type AuctionDetailSnapshotWsEventResponseDto,
    type AuctionItemEntity,
    type AuctionListingSnapshotWsEventResponseDto,
    type AuctionsListingDto,
    AuctionsWsInEventEnum,
    AuctionsWsOutEvent,
    type AuctionWsEventDtoEvents,
    type AuthorizeWsEventBodyDto,
    type ObserveAuctionDetailWsBodyDto,
    type ObserveAuctionListingWsBodyDto,
    type UserBailAuthorizedWsEventResponseDto,
    type UserMaxBidderRestoredWsEventResponseDto,
    type UserMetaSnapshotWsEventResponseDto,
    type UserOutBidWsEventResponseDto,
} from '~/apiClient';
import { AUCTION_DETAIL_QUERY_KEY_PREFIX, AUCTION_LISTING_QUERY_KEY_PREFIX, USER_BIDS_QUERY_KEY_PREFIX } from '~/constants/queryKeyPrefix';

type WsMessage = AuctionWsEventDtoEvents | UserMetaSnapshotWsEventResponseDto;

enum WsEntityType {
    Listing,
    Detail,
}

type WsEntityManagerReturn = {
    sendObserveMessage: (options?: { subscribeOnly?: boolean }) => void;
    handleSnapshotMessage: (message: WsMessage) => boolean;
};

type MakeWsEntityManager = (entityType: WsEntityType) => WsEntityManagerReturn;

const isAuctionListingSnapshot = (message: WsMessage): message is AuctionListingSnapshotWsEventResponseDto =>
    message.event === AuctionsWsOutEvent.AuctionListingSnapshot;

const isAuctionDetailSnapshot = (message: WsMessage): message is AuctionDetailSnapshotWsEventResponseDto =>
    message.event === AuctionsWsOutEvent.AuctionDetailSnapshot;

const isUserMetaSnapshot = (message: WsMessage): message is UserMetaSnapshotWsEventResponseDto =>
    message.event === AuctionsWsOutEvent.UserMetaSnapshot;

const isUserOutBid = (message: WsMessage): message is UserOutBidWsEventResponseDto => message.event === AuctionsWsOutEvent.UserOutBid;

const isUserBailAuthorized = (message: WsMessage): message is UserBailAuthorizedWsEventResponseDto =>
    message.event === AuctionsWsOutEvent.UserBailAuthorized;

const isUserMaxBidderRestored = (message: WsMessage): message is UserMaxBidderRestoredWsEventResponseDto =>
    message.event === AuctionsWsOutEvent.UserMaxBidderRestored;

const isUserOffersUpdate = (message: WsMessage): message is UserMaxBidderRestoredWsEventResponseDto =>
    message.event === AuctionsWsOutEvent.UserOffersUpdate;

export default defineNuxtPlugin({
    name: 'wsAuctions',
    dependsOn: ['vueQuery'],

    setup(nuxtApp) {
        const runtimeConfig = useRuntimeConfig();
        const { webSocket: webSocketConfig } = useAppConfig();
        const {
            isLoggedIn,
            state: { rawToken },
        } = useAuthUtils();
        const queryClient = useQueryClient();
        const queryCache = queryClient.getQueryCache();
        const safeJsonParse = useSafeJsonParse();
        const localePath = useLocalePath();
        const { notifyWarning, notifySuccess } = useNotification();
        const route = useRoute();
        const getRouteBaseName = useRouteBaseName();
        const { tt, tn } = useTypedI18n();
        const { logError } = useLogs();

        const wsEndpoint = `${runtimeConfig.public.wsBaseUrl}/auctions`;

        const messageFormatter = {
            ping: (): string => JSON.stringify({ event: AuctionsWsInEventEnum.Ping }),

            authorize: (token: string): string =>
                JSON.stringify({ event: AuctionsWsInEventEnum.Authorize, data: { token } satisfies AuthorizeWsEventBodyDto }),

            unauthorize: (): string => JSON.stringify({ event: AuctionsWsInEventEnum.Unauthorize }),

            observeListing: (ids: string[]): string =>
                JSON.stringify({
                    event: AuctionsWsInEventEnum.ObserveAuctionListing,
                    data: { auctions: ids } satisfies ObserveAuctionListingWsBodyDto,
                }),

            observeDetail: (id: string | null): string =>
                JSON.stringify({
                    event: AuctionsWsInEventEnum.ObserveAuctionDetail,
                    data: { auctionId: id } satisfies ObserveAuctionDetailWsBodyDto,
                }),
        };

        const makeWsEntityManager: MakeWsEntityManager = entityType => {
            const queryKeyPrefix = entityType === WsEntityType.Listing ? AUCTION_LISTING_QUERY_KEY_PREFIX : AUCTION_DETAIL_QUERY_KEY_PREFIX;
            const observedIds = ref<string[]>([]);

            const sendObserveMessage: WsEntityManagerReturn['sendObserveMessage'] = ({ subscribeOnly } = {}) => {
                (!subscribeOnly || observedIds.value.length) &&
                    send(
                        entityType === WsEntityType.Listing
                            ? messageFormatter.observeListing(observedIds.value)
                            : messageFormatter.observeDetail(observedIds.value[0] ?? null)
                    );
            };

            const handleSnapshotMessage = (message: WsMessage): boolean => {
                if (entityType === WsEntityType.Listing && isAuctionListingSnapshot(message)) {
                    const { data: snapshotData } = message;
                    const snapshotDataMap = lodashKeyBy(snapshotData, 'id');

                    const cache = getCache<AuctionsListingDto>({ active: true });
                    const cacheData = cache?.state.data;

                    if (cacheData) {
                        cache.setData({
                            ...cacheData,
                            auctions: cacheData.auctions.map(auction => ({ ...auction, ...snapshotDataMap[auction.id] })),
                        });
                    }

                    return true;
                }

                if (entityType === WsEntityType.Detail && (isAuctionDetailSnapshot(message) || isUserMetaSnapshot(message))) {
                    const { data: snapshotData } = message;

                    const cache = getCache<AuctionItemEntity>({ active: true });
                    const cacheData = cache?.state.data;

                    if (cacheData) {
                        cache.setData({ ...cacheData, ...snapshotData });
                    }

                    return true;
                }

                return false;
            };

            const getCache = <T>(options: { active?: boolean } = {}): Query<T> | undefined => {
                const caches = queryCache.findAll({
                    queryKey: [queryKeyPrefix],
                    ...(options.active && { type: 'active' }),
                });

                return caches[0] as Query<T> | undefined;
            };

            const isSetCacheEvent = (event: QueryCacheNotifyEvent): boolean =>
                event.type === 'updated' && event.query.queryKey[0] === queryKeyPrefix && event.action.type === 'success';

            const isUnsetCacheEvent = (event: QueryCacheNotifyEvent): boolean =>
                event.type === 'observerRemoved' && event.query.queryKey[0] === queryKeyPrefix && event.query.observers.length === 0;

            const handleCacheEvent = (event: QueryCacheNotifyEvent): void => {
                if (isSetCacheEvent(event)) {
                    if (entityType === WsEntityType.Listing) {
                        const data = event.query.state.data as AuctionsListingDto;
                        observedIds.value = data?.auctions?.map(auction => auction.id);
                    } else {
                        const data = event.query.state.data as AuctionItemEntity;
                        observedIds.value = [data.id];
                    }
                } else if (isUnsetCacheEvent(event)) {
                    observedIds.value = [];
                }
            };

            const initObserved = (): void => {
                const cache = getCache();
                const cacheData = cache?.state.data;

                if (cacheData) {
                    if (entityType === WsEntityType.Listing) {
                        observedIds.value = (cacheData as AuctionsListingDto).auctions.map(auction => auction.id);
                    } else {
                        observedIds.value = [(cacheData as AuctionItemEntity).id];
                    }
                }
            };

            watch(observedIds, (value, oldValue) => {
                if (value.join() === oldValue.join() || status.value !== 'OPEN') return;
                sendObserveMessage();
            });

            queryCache.subscribe(handleCacheEvent);

            nuxtApp.hooks.hook('app:created', () => {
                initObserved();
            });

            return { sendObserveMessage, handleSnapshotMessage };
        };

        const handleUserOutBidMessage = (message: WsMessage): boolean => {
            if (isUserOutBid(message)) {
                if (getRouteBaseName(route) === 'auction-slug' && route.params.slug === message.data.slug) {
                    notifyWarning({
                        title: tt('notifications.userOutBid.base', {
                            subject: tt('notifications.userOutBid.inAuction'),
                            prevBidAmount: tn(message.data.prevBidAmount, 'currency'),
                            currentBidAmount: tn(message.data.currentBidAmount, 'currency'),
                        }),
                    });
                } else {
                    notifyWarning({
                        title: tt('notifications.userOutBid.base', {
                            subject: tt('notifications.userOutBid.linkedAuction', {
                                brand: message.data.brand,
                                model: message.data.model,
                                version: message.data.version,
                            }),
                            prevBidAmount: tn(message.data.prevBidAmount, 'currency'),
                            currentBidAmount: tn(message.data.currentBidAmount, 'currency'),
                        }),
                        clickHandler: () => navigateTo(localePath({ name: 'auction-slug', params: { slug: message.data.slug } })),
                    });
                }

                return true;
            }

            return false;
        };

        const handleUserBailAuthorizedMessage = (message: WsMessage): boolean => {
            if (isUserBailAuthorized(message)) {
                if (getRouteBaseName(route) === 'auction-slug' && route.params.slug === message.data.slug) {
                    notifySuccess({
                        title: tt('notifications.userBailAuthorized.inAuction'),
                    });
                } else {
                    notifySuccess({
                        title: tt('notifications.userBailAuthorized.linkedAuction', {
                            brand: message.data.brand,
                            model: message.data.model,
                        }),
                        clickHandler: () => navigateTo(localePath({ name: 'auction-slug', params: { slug: message.data.slug } })),
                    });
                }

                return true;
            }

            return false;
        };

        const handleUserMaxBidderRestoredMessage = (message: WsMessage): boolean => {
            if (isUserMaxBidderRestored(message)) {
                if (getRouteBaseName(route) === 'auction-slug' && route.params.slug === message.data.slug) {
                    notifySuccess({
                        title: tt('notifications.userMaxBidderRestored.base', {
                            subject: tt('notifications.userMaxBidderRestored.inAuction'),
                            prevBidAmount: tn(message.data.prevBidAmount, 'currency'),
                            currentBidAmount: tn(message.data.currentBidAmount, 'currency'),
                        }),
                    });
                } else {
                    notifySuccess({
                        title: tt('notifications.userMaxBidderRestored.base', {
                            subject: tt('notifications.userMaxBidderRestored.linkedAuction', {
                                brand: message.data.brand,
                                model: message.data.model,
                                version: message.data.version,
                            }),
                            prevBidAmount: tn(message.data.prevBidAmount, 'currency'),
                            currentBidAmount: tn(message.data.currentBidAmount, 'currency'),
                        }),
                        clickHandler: () => navigateTo(localePath({ name: 'auction-slug', params: { slug: message.data.slug } })),
                    });
                }

                return true;
            }

            return false;
        };

        const handleUserOffersUpdate = (message: WsMessage): boolean => {
            if (isUserOffersUpdate(message) && getRouteBaseName(route) === 'account-bids') {
                queryClient.invalidateQueries({ queryKey: [USER_BIDS_QUERY_KEY_PREFIX] });

                return true;
            }

            return false;
        };

        const auctionListingManager = makeWsEntityManager(WsEntityType.Listing);
        const auctionDetailManager = makeWsEntityManager(WsEntityType.Detail);

        const { status, send } = useWebSocket(wsEndpoint, {
            autoReconnect: {
                ...webSocketConfig.autoReconnect,
                onFailed() {
                    logError('Error connecting WS:', { endpoint: wsEndpoint });
                },
            },

            heartbeat: {
                ...webSocketConfig.heartbeat,
                message: messageFormatter.ping(),
            },

            onConnected(ws) {
                isLoggedIn.value && rawToken.value && ws.send(messageFormatter.authorize(rawToken.value));
                auctionListingManager.sendObserveMessage({ subscribeOnly: true });
                auctionDetailManager.sendObserveMessage({ subscribeOnly: true });
            },

            onMessage(_, event) {
                const eventData = safeJsonParse<WsMessage>(event.data, 'WS /auctions message');
                if (!eventData) return;

                for (const handler of [
                    auctionListingManager.handleSnapshotMessage,
                    auctionDetailManager.handleSnapshotMessage,
                    handleUserOutBidMessage,
                    handleUserBailAuthorizedMessage,
                    handleUserMaxBidderRestoredMessage,
                    handleUserOffersUpdate,
                ]) {
                    if (handler(eventData)) return;
                }
            },
        });

        watch(isLoggedIn, (value, oldValue) => {
            if (value === oldValue || status.value !== 'OPEN') return;
            send(value && rawToken.value ? messageFormatter.authorize(rawToken.value) : messageFormatter.unauthorize());
        });
    },
});
