import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import type { ComputedRef, Ref } from 'vue';

interface UseResponsive {
    breakpoints: ReturnType<typeof useBreakpoints<keyof typeof breakpointsTailwind>>;
    isMobile: Ref<boolean>;
    isMobileOrTablet: Ref<boolean>;
    isTabletOrDesktop: Ref<boolean>;
    isLgOrGreater: Ref<boolean>;
    isTablet: ComputedRef<boolean>;
}

export const useResponsive = (): UseResponsive => {
    const breakpoints = useBreakpoints(breakpointsTailwind);

    return {
        breakpoints,
        isMobile: breakpoints.smaller('md'),
        isMobileOrTablet: breakpoints.smaller('lg'),
        isTabletOrDesktop: breakpoints.greaterOrEqual('md'),
        isLgOrGreater: breakpoints.greaterOrEqual('lg'),
        isTablet: computed(() => breakpoints.active().value === 'md'),
    };
};
