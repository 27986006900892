<!-- TODO: To be extended to support type info|success|etc. -->

<script setup lang="ts">
import type { AlertColor } from '#ui/types';
import type { PropType } from 'vue';

type MessageType = 'error';
type TypeStyle = { color: AlertColor; icon: string };

const props = defineProps({
    type: { type: String as PropType<MessageType>, required: true },
    title: { type: String, required: true },
    description: { type: String, default: null },
});

const styles: Record<MessageType, TypeStyle> = {
    error: {
        color: 'red',
        icon: 'i-heroicons-x-circle-16-solid',
    },
};

const style = computed(() => styles[props.type]);
</script>

<template>
    <UAlert :icon="style.icon" :color="style.color" variant="soft" v-bind="{ title, description }" />
</template>
