import { useState } from '#app';
import type { DehydratedState, VueQueryPluginOptions } from '@tanstack/vue-query';
import { VueQueryPlugin, QueryClient, hydrate, dehydrate } from '@tanstack/vue-query';

export default defineNuxtPlugin({
    name: 'vueQuery',
    setup(nuxtApp) {
        const vueQueryState = useState<DehydratedState | null>('vueQuery');

        const queryClient = new QueryClient({
            defaultOptions: {
                queries: {
                    retry: false,
                    refetchOnWindowFocus: false,
                    staleTime: 5000, // To avoid client re-fetch after SSR
                    // Other default values:
                    // - cacheTime: 5 min
                },
            },
        });
        const options: VueQueryPluginOptions = { queryClient, enableDevtoolsV6Plugin: true };

        nuxtApp.vueApp.use(VueQueryPlugin, options);

        if (import.meta.server) {
            nuxtApp.hooks.hook('app:rendered', () => {
                vueQueryState.value = dehydrate(queryClient);
            });
        }

        if (import.meta.client) {
            nuxtApp.hooks.hook('app:created', () => {
                hydrate(queryClient, vueQueryState.value);
            });
        }
    },
});
