import lodashPick from 'lodash-es/pick';

export default defineNuxtPlugin({
    name: 'error-handler',
    dependsOn: ['sentry'],
    async setup(nuxtApp) {
        nuxtApp.vueApp.config.errorHandler = (error, instance, info): void => {
            const { logError } = useLogs();

            logError('Vue error', { error, instance: lodashPick(instance, ['$attrs', '$data', '$el', '$options', '$props']), info });
        };
    },
});
