import type { ComputedRef } from 'vue';

interface StackTrace {
    text: string;
    internal: boolean;
}

type UseDevelopmentErrorReturn = {
    message: ComputedRef<string | undefined>;
    stacktrace: ComputedRef<StackTrace[]>;
};

export const useDevelopmentError = (): UseDevelopmentErrorReturn => {
    const error = useError();

    const message = computed<string | undefined>(() => {
        if (!import.meta.dev) {
            return;
        }

        if (error.value?.statusMessage && error.value?.message && error.value.statusMessage !== error.value.message) {
            return `${error.value.statusMessage} - ${error.value.message}`;
        }

        return error.value?.statusMessage ?? error.value?.message ?? error.toString();
    });

    // This routine has been copied from https://github.com/nuxt/nuxt/blob/7d942f1b5b0820d37ba1219e3a12312a46d1ccf7/packages/nuxt/src/app/components/nuxt-error-page.vue
    const stacktrace = computed<StackTrace[]>(() =>
        error.value?.stack && import.meta.dev
            ? error.value.stack
                  .split('\n')
                  .splice(1)
                  .map((line: string) => {
                      const text = line
                          .replace('webpack:/', '')
                          .replace('.vue', '.js') // TODO: Support sourcemap
                          .trim()
                          .replace(/(<([^>]+)>)/gi, ''); // Remove html already formatted

                      return {
                          text,
                          internal:
                              (line.includes('node_modules') && !line.includes('.cache')) ||
                              line.includes('internal') ||
                              line.includes('new Promise'),
                      };
                  })
            : []
    );

    return {
        message,
        stacktrace,
    };
};
