import { readonly, type Ref } from 'vue';

import { TimeSyncStatus } from '~/types/timeSync.type';

type UseTimeSyncReturn = {
    timeSyncNow: Readonly<Ref<number>>;
    timeSyncOffset: Readonly<Ref<number | null>>;
    timeSyncRoundTrip: Readonly<Ref<number | null>>;
    timeSyncStatus: Readonly<Ref<TimeSyncStatus>>;
    _raw: {
        now: Ref<number>;
        offset: Ref<number | null>;
        roundTrip: Ref<number | null>;
        status: Ref<TimeSyncStatus>;
    };
};

export const useTimeSync = (): UseTimeSyncReturn => {
    const now = useState<number>('timeSync', () => Date.now());
    const offset = useState<number | null>('timeSyncOffset', () => null);
    const roundTrip = useState<number | null>('timeSyncRoundTrip', () => null);
    const status = useState<TimeSyncStatus>('timeSyncStatus', () => TimeSyncStatus.Pending);

    return {
        timeSyncNow: readonly(now),
        timeSyncOffset: readonly(offset),
        timeSyncRoundTrip: readonly(roundTrip),
        timeSyncStatus: readonly(status),
        // For exclusive use of the timeSync plugin
        _raw: {
            now,
            offset,
            roundTrip,
            status,
        },
    };
};
